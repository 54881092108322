import { Injectable } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";

import { ApplePayService } from 'src/app/services/payments/apple-pay.service';
import { KlarnaService } from 'src/app/services/payments/klarna.service';
import { PaypalService } from 'src/app/services/payments/paypal.service';
import { WalletService } from 'src/app/services/payments/wallet.service';

import { WeclappService } from 'src/app/services/integrations/weclapp.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  paymentMethodCodes: any = {
    //'Apple Pay': 'applepay',
    'Barzahlung': 'bar',
    'Rechnung': 'invoice',
    'PayPal': 'paypal',
    'klarna': 'klarna',
  };

  paymentMethods: any = [
    {
      id: 'applepay',
      icon: 'logo-apple',
      title: 'Apple Pay',
    },
    {
      id: 'paypal',
      image: './assets/img/paypal.png',
      title: 'PayPal'
    },
    {
      id: 'klarna',
      image: './assets/img/klarna.png',
      title: 'klarna'
    },
    {
      id: 'Barzahlung',
      icon: 'cash',
      title: 'Barzahlung',
    },
    {
      id: 'Rechnung',
      icon: 'document',
      title: 'Rechnung',
    },
  ];

  paymentOptions: any = {
    //paymentMethod: window.localStorage.getItem('paymentMethod') || '',
  };

  constructor(
    private applePay: ApplePayService,
    private configService: ConfigService,
    private klarna: KlarnaService,
    private payPal: PaypalService,
    private wallet: WalletService,
    private weclapp: WeclappService,
  ) {
  }

  getPaymentDetails() {
    return {
      'method_id': this.getPaymentMethodCode(),
      'method_title': this.getPaymentMethodTitle(),
      'paid': this.paid(),
    };
  }

  getPaymentMethod() {
    return this.getPaymentOptions().paymentMethod;
  }

  getPaymentMethodCode() {
    return this.paymentMethodCodes[this.getPaymentMethod()] || null;
  }

  getPaymentMethods() {
    return this.paymentMethods;
  }

  getPaymentMethodTitle() {
    return this.getPaymentMethod();
  }

  getPaymentOptions() {
    return this.paymentOptions;
  }

  paid() {
    let blPaid: boolean = false;
    switch (this.getPaymentMethod()) {
      case 'apple_pay':
        blPaid = true;
        break;
      case 'Barzahlung':
        blPaid = true;
        break;
      case 'klarna':
        blPaid = true;
        break;
      case 'Kundenkarte':
        blPaid = false;
        break;
      case 'PayPal':
        blPaid = true;
        break;
      case 'Rechnung':
        blPaid = false;
        break;
    }
    return blPaid;
  }

  pay() {
    return new Promise((resolve, reject) => {
      switch (this.getPaymentMethod()) {
        case 'apple_pay':
          this.applePay.pay().then(resolve).catch(reject);
          break;
        case 'Barzahlung':
          this.payWithCash().then(resolve).catch(reject);
          break;
        case 'Rechnung':
          this.payWithInvoice().then(resolve).catch(reject);
          break;
        case 'PayPal':
          this.payPal.pay().then(resolve).catch(reject);
          break;
        case 'klarna':
          this.klarna.setPaymentOptions(this.getPaymentOptions());
          this.klarna.pay().then(resolve).catch(reject);
          break;
        case 'wallet':
          this.wallet.pay().then(resolve).catch(reject);
          break;
        default:
          if (!!this.configService.useWeclappExtension()) {
            this.weclapp.setPaymentOptions(this.getPaymentOptions());
            this.weclapp.pay().then(resolve).catch(reject);
          } else {
            reject('error_payment_method_not_supported');
          }
          break;
      }
    });
  }

  payWithCash() {
    return new Promise((resolve, reject) => {
      resolve({
        success: true,
      });
    })
  }

  payWithInvoice() {
    return new Promise((resolve, reject) => {
      resolve({
        success: true,
      });
    })
  }

  setPaymentMethod(paymentMethod: string) {
    this.paymentOptions.paymentMethod = paymentMethod;
    window.localStorage.setItem('paymentMethod', paymentMethod);
  }

}