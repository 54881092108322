import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';

import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ObjectsService } from 'src/app/services/core/objects.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  appPages: any;

  cacheKey: string = 'menu_app_pages';

  project: project;

  constructor(
    private cache: CacheService,
    private config: ConfigService,
    private events: EventsService,
    private menu: MenuController,
    private objects: ObjectsService,
    private tools: ToolsService,
  ) {
    this.initEvents();
  }

  applyObjectTypesToMenu(types: any[], appPages: any | null = null) {

    if (!types || !types.length) {
      return false;
    }

    appPages = appPages || {};
    appPages.main = appPages.main || [];

    const existingKeys: string[] = appPages.main.map((entry: any) => {
      return entry.key;
    });

    types.forEach((type: any) => {
      if (!!type.active) {

        const appPageByType: any = {
          active: true,
          description: `${type.value.description || ''}`,
          icon: type.value.icon || 'file-tray-outline',
          key: type.value.name,
          name: type.value.name,
          label: type.value.name,
          uid: `object_type_${type.uid}`,
          url: `objects/types/${type.uid}`,
        };

        if (existingKeys.indexOf(appPageByType.uid) === -1) {
          appPages.main.push(appPageByType);
        }
      }
    });

    appPages.main = this.tools.unique(appPages.main, ['key']);

    return appPages;
  }

  close(blForce: boolean = false) {
    if (blForce || (window.innerWidth <= 768)) {
      this.menu.close();
    }
  }

  enable() {
    this.events.publish('menu:show');

    if (window.innerWidth > 768) {
      //this.open();
    }
  }

  async getAppPages(key: string, blAppendObjectTypes: boolean = false, blLoadCustom: boolean = true, blForceRefresh: boolean = false) {

    if (!this.appPages || (!!blAppendObjectTypes || !!blLoadCustom || !!blForceRefresh)) {
      try {
        this.appPages = await this.initAppPages(blAppendObjectTypes, blLoadCustom, blForceRefresh);
      } catch (e) {
        console.warn('loading appPages failed', e);
      }
    }

    return (!!this.appPages && !!this.appPages[key] ? this.appPages[key] : []);
  }

  getAppPagesKeys() {
    return Object.keys(this.appPages);
  }

  async getCacheKey() {
    return `${this.cacheKey}_${!!this.project && this.project.uid ? this.project.uid : 0}`;
  }

  async getCustomPages(blForceRefresh: boolean = false) {
    const cacheKey: string = await this.getCacheKey();
    const fromCache: cacheItem|null = await this.cache.get(cacheKey, -1);
    return (!!fromCache && !!fromCache.data ? fromCache.data : []);
  }

  getParentMenuName(menuName: string) {
    let parentMenuName: string | null = null;
    const appPageMainNames: string[] = Object.keys(this.appPages);

    if (appPageMainNames && appPageMainNames.length) {
      appPageMainNames.forEach((appPageMainName: string) => {
        const appPagesByName: appPage[] = this.appPages[appPageMainName];

        if (appPagesByName && appPagesByName.length) {
          appPagesByName.forEach((appPage: appPage) => {
            if (appPage.key === menuName && (appPageMainName !== appPage.key)) {
              parentMenuName = appPageMainName;
            }
          });
        }
      });
    }

    return parentMenuName;
  }

  hide() {
    this.events.publish('menu:hide');
  }

  initAppPages(blAppendObjectTypes: boolean = false, blLoadCustom: boolean = true, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const customPages: any = (!!blLoadCustom ? await this.getCustomPages(blForceRefresh) : []);
        const customPagesKeys: string[] = Object.keys(customPages);

        this.appPages = null;
        let appPages = (!!customPagesKeys && !!customPagesKeys.length ? customPages : this.config.getConfigParam('appPages')) || [];

        if (!!blAppendObjectTypes) {
          try {
            const objectTypes: any = await this.objects.getTypes({}, blForceRefresh);

            if (!!objectTypes && !!objectTypes.length) {
              appPages = await this.applyObjectTypesToMenu(objectTypes, appPages);
            }
          } catch (e: any) {
            console.warn('initAppPages: loading objectTypes failed', e);
          }
        }

        resolve(appPages);
      } catch (e) {
        reject(e);
      }
    });
  }

  initEvents() {
    this.events.subscribe('project:current:updated', (project: project) => {
      this.project = project;
    });
  }

  isOpen() {
    return this.menu.isOpen();
  }

  open() {
    return this.menu.open();
  }

  async setCustomPages(pages: appPage[], key: string) {
    this.appPages[key] = pages;

    const cacheKey: string = await this.getCacheKey();
    return this.cache.set(cacheKey, this.appPages);
  }

  toggle() {
    return this.menu.toggle();
  }

}