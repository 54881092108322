import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { PagesService } from 'src/app/services/extensions/pages.service';
import { PostsService } from "src/app/services/posts/posts.service";
import { ShopService } from "src/app/services/ecommerce/shop.service";

import { BasketPage } from "src/app/pages/shop/basket/basket.page";
import { CheckoutPage } from "src/app/pages/shop/checkout/checkout.page";
import { DetailPage } from "src/app/pages/detail/detail.page";
import { DynamicPage } from "src/app/pages/pages/dynamic/dynamic.page";
import { PaymentPage } from "src/app/pages/shop/payment/payment.page";
import { PostPage } from "src/app/pages/post/post/post.page";

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService {

  constructor(
    private events: EventsService,
    private loading: LoadingController,
    private modalService: ModalService,
    private pages: PagesService,
    private posts: PostsService,
    private shop: ShopService,
  ) {

  }

  init() {

    this.events.subscribe("view:basket", () => {
      this.viewBasket();
    });

    this.events.subscribe("view:checkout", () => {
      this.viewCheckout();
    });

    this.events.subscribe("view:page", (data: any) => {
      if (typeof data === "number") {
        this.viewPage(data);
      } else {
        this.viewPage(data.uid, data);
      }
    });

    this.events.subscribe("view:payments", () => {
      this.viewPayments();
    });

    this.events.subscribe("view:post", (data: any) => {
      if (typeof data === "number") {
        this.viewPost(data);
      } else {
        this.viewPost(data.uid, data);
      }
    });

    this.events.subscribe("view:product", (data: any) => {
      if (typeof data === "number") {
        this.viewProduct(data);
      } else {
        this.viewProduct(data.uid, data);
      }
    });

    this.events.subscribe("view:profile", (data: any) => {
      if (typeof data === "number") {
        this.viewProfile(data);
      } else {
        this.viewProfile(data.uid, data);
      }
    });

  }

  async viewBasket() {

    const basketModal: any = await this.modalService.create({
      component: BasketPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: "defaultModal",
    });

    await this.modalService.present(basketModal);
  }

  viewCategory(category: any) {
    this.events.publish("view:category", category);
  }

  async viewCheckout() {

    const checkoutModal: any = await this.modalService.create({
      component: CheckoutPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: "defaultModal",
    });

    this.modalService.present(checkoutModal);
  }

  viewPage(itemId: number, page: page = null) {
    new Promise(async (resolve, reject) => {
      if (page) {
        resolve(page);
      } else {

        const loading: any = await this.loading.create({
          backdropDismiss: false,
          spinner: "circular",
        });

        await loading.present();

        this.pages
          .getByUid(itemId)
          .then((page: page) => {
            loading.dismiss();
            resolve(page);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
      }
    })
      .then(async (page: page) => {
        this.pages.setCurrent(page);
        this.pages.isModal(true);

        const pageModal: any = await this.modalService.create({
          component: DynamicPage,
          animated: true,
          presentingElement: await this.modalService.getTop(),
          cssClass: "defaultModal",
        });

        this.modalService.present(pageModal);
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  async viewPayments() {

    const paymentsModal: any = await this.modalService.create({
      component: PaymentPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: "defaultModal",
    });

    this.modalService.present(paymentsModal);
  }

  viewPost(itemId: number, post: post = null) {
    new Promise(async (resolve, reject) => {
      if (post) {
        resolve(post);
      } else {

        const loading: any = await this.loading.create({
          backdropDismiss: false,
          spinner: "circular",
        });

        await loading.present();

        this.posts
          .getPostByUid(itemId)
          .then((post: post) => {
            loading.dismiss();
            resolve(post);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
      }
    })
      .then(async (post: post) => {
        this.posts.detailItem((post || {}) as any);

        const postModal: any = await this.modalService.create({
          component: PostPage,
          animated: true,
          presentingElement: await this.modalService.getTop(),
          cssClass: "defaultModal",
        });

        this.modalService.present(postModal);
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  async viewProfile(userId: number, user: user | null = null) {
    console.log('> viewProfile: should open pipeline app', userId, user);
  }

  async viewProduct(productId: number, product = null) {
    new Promise(async (resolve, reject) => {
      if (product) {
        resolve(product);
      } else {

        const loading: any = await this.loading.create({
          backdropDismiss: false,
          spinner: "circular",
        });

        await loading.present();

        this.shop
          .getProductByUid(productId)
          .then((product: product) => {
            loading.dismiss();
            resolve(product);
          })
          .catch((error: any) => {
            loading.dismiss();
            reject(error);
          });
      }
    })
      .then(async (product: product) => {

        const productModal: any = await this.modalService.create({
          component: DetailPage,
          animated: true,
          componentProps: {
            product: product,
          },
          presentingElement: await this.modalService.getTop(),
          cssClass: "defaultModal",
        });

        this.modalService.present(productModal);
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

}